import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { GlobalCacheConfig } from 'ts-cacheable';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ExtendedOrgUser } from '@core/models/user.model';

GlobalCacheConfig.maxAge = 10 * 60 * 1000;

if (environment.ENABLE_SENTRY_TRACING) {
  Sentry.init({
    dsn: environment.SENTRY_DSN_EXTENSION,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: environment.SENTRY_TRACES_SAMPLE_RATE,
    ignoreErrors: ['Non-Error exception captured'],
    environment: environment.ENVIRONMENT,
    beforeSend(event) {
      setSentryUserContext(event);
      return event;
    },
    release: environment.RELEASE,
  });

  // adding null scope here so that I am able to edit it in before sending event to sentry
  // This is added because configureScope will not work in beforeSend of sentry
  Sentry.getCurrentScope().setUser({
    id: null,
    userId: null,
    orgId: null,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  /* eslint-disable no-console */
  .catch((err) => console.error(err));

window.isFyleEvent = isFyleEvent.bind(this);

/* eslint-disable complexity */
function isFyleEvent(event: MessageEvent) {
  const origin = event.target && (event.target as any).location.origin;
  const netlifyUrl = environment.EXTENSION_NETLIFY_URL;

  if (origin && origin === netlifyUrl) {
    //  emitted by angular
    if (!event.data || event.data === '' || event.data.type === 'webpackOk') {
      return false;
    }

    if ((typeof event.data === 'string' || event.data instanceof String) && event.data) {
      return true;
    }
    return false;
  }
  return false;
}

// add user details to sentry event
function setSentryUserContext(event: Sentry.Event) {
  const eouStr = localStorage.getItem('fyle.user');
  if (eouStr) {
    const extendedOrgUser: ExtendedOrgUser = JSON.parse(eouStr);
    if (!event.user) {
      event.user = {};
    }
    // sentry requires a unique identifier for user
    event.user.id = extendedOrgUser.ou.id;
    event.user.userId = extendedOrgUser.ou.user_id;
    event.user.orgId = extendedOrgUser.ou.org_id;
  }
}
